import useFetchService from '@/composables/useFetchService';

export default function useHomeService() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const runtimeConfig = useRuntimeConfig();
  const fetchService = useFetchService({ base: runtimeConfig.public.apiUrl });

  /********************
   * FUNCTIONS         *
   ********************/
  function getHomeData(lang: string) {
    return fetchService.get('/home/', lang, {}, 2);
  }

  return {
    getHomeData,
  };
}
