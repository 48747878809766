<script setup lang="ts">
import dayjs from '@/utils/day';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiMagazinePostsProps {
  loading?: boolean;
  posts?: any[];
}
const props = withDefaults(defineProps<CiMagazinePostsProps>(), {
  loading: false,
  posts: () => [],
});

/********************
 * COMPOSITIONS      *
 ********************/
const runtimeConfig = useRuntimeConfig();
const { $gettext } = useGettext();
const { locale } = useI18n();

/********************
 * REFS & VARS       *
 ********************/
const smallPosts = computed(() => {
  const posts = [];
  for (let i = 0; i < 2 && props.posts[i]; i += 1) {
    const post = props.posts[i];

    if (post) {
      posts.push(post);
    }
  }
  return posts;
});

const largePost = computed(() => {
  if (props.posts && props.posts[2]) {
    return props.posts[2];
  }
  return null;
});

const i18nHeadline = computed(() => {
  return $gettext('<strong>Latest</strong><span class="light"> Posts</span>');
});

/********************
 * FUNCTIONS         *
 ********************/
function formatDate(value) {
  return !value ? '' : dayjs(value).format('DD. MMMM YYYY');
}

function getPostLink(post) {
  return post.type === 'diy'
    ? `${runtimeConfig.public.magazineUrl}${locale.value}/${post.slug.slug}/1`
    : `${runtimeConfig.public.magazineUrl}${locale.value}/${post.slug.slug}`;
}

function getCategoryLink(mainCategory, parentCategory) {
  if (!mainCategory || !parentCategory) {
    return;
  }
  return `${runtimeConfig.public.magazineUrl}${locale.value}/${parentCategory.slug.slug}/${mainCategory.slug.slug}`;
}
</script>

<template>
  <section
    v-if="loading || posts.length"
    class="section section--magazine-posts container my-12 grid grid-cols-12 gap-x-[30px] pb-0"
  >
    <div class="col-span-12 flex flex-col items-center justify-center">
      <h2
        class="m-0 mb-4"
        v-html="i18nHeadline"
      />
    </div>

    <template v-if="posts.length">
      <!-- small posts -->
      <div
        v-for="post in smallPosts"
        :key="post.id"
        class="relative z-10 col-span-12 mb-4 h-60 md:col-span-6 md:h-80 lg:col-span-3 lg:h-[25rem]"
      >
        <a
          :href="getPostLink(post)"
          class="relative block h-full rounded shadow-ci after:absolute after:left-0 after:top-0 after:block after:size-full after:opacity-0 after:shadow-ci-sm after:transition-opacity after:duration-500 after:content-[''] hover:cursor-pointer hover:no-underline hover:after:rounded hover:after:opacity-100"
          target="_blank"
          rel="noopener"
        >
          <div class="absolute z-10">
            <div class="p-6">
              <div class="text-shadow font-bold text-white">
                {{ post.post_content[0].title }}
              </div>
              <div class="mt-1">
                <span class="text-shadow text-sm font-medium text-primary">
                  {{ formatDate(post.created_at) }}
                </span>
              </div>
            </div>
          </div>
          <div class="relative -z-1 h-full">
            <CiImageFocal
              v-if="post.post_content[0].image"
              :image="post.post_content[0].image"
              :alt="post.post_content[0].title"
              class="size-full after:absolute after:left-0 after:top-0 after:block after:size-full after:bg-gradient-to-b after:from-black after:to-[rgba(14,31,18,0)] after:opacity-80 after:content-['']"
            />
          </div>
        </a>
        <a
          v-if="post.main_category"
          :href="getCategoryLink(post.main_category, post.main_category.parent)"
          class="button button--light button--radius-small text-small-book absolute mb-6 ml-6 px-4 py-1 font-bold"
          style="z-index: 10; bottom: 0"
          target="_blank"
          rel="noopener"
        >
          {{ post.main_category.title }}
        </a>
      </div>

      <!-- large posts -->
      <div
        v-if="largePost"
        class="relative z-10 col-span-12 mb-4 h-auto md:h-80 lg:col-span-6 lg:h-[25rem]"
      >
        <a
          v-if="largePost.main_category"
          :href="getCategoryLink(largePost.main_category, largePost.main_category.parent)"
          class="button button--primary button--radius-small text-small-book absolute z-10 ml-6 mt-6 px-4 py-1 font-bold"
          target="_blank"
          rel="noopener"
        >
          {{ largePost.main_category.title }}
        </a>
        <a
          :href="getPostLink(largePost)"
          class="relative grid h-full grid-cols-12 rounded shadow-ci after:absolute after:left-0 after:top-0 after:block after:size-full after:opacity-0 after:shadow-ci-sm after:transition-opacity after:duration-500 after:content-[''] hover:cursor-pointer hover:no-underline hover:after:rounded hover:after:opacity-100"
          target="_blank"
          rel="noopener"
        >
          <div class="col-span-12 md:col-span-6">
            <div class="relative -z-1 h-60 w-full md:h-full">
              <CiImageFocal
                v-if="largePost.post_content[0].image"
                :image="largePost.post_content[0].image"
                :alt="largePost.post_content[0].title"
              />
            </div>
          </div>
          <div class="col-span-12 h-full md:col-span-6">
            <div class="rounded-right z-10 flex h-full flex-col items-start bg-white p-4">
              <div class="font-bold text-black">
                {{ largePost.post_content[0].title }}
              </div>
              <div class="mb-6">
                <span class="text-sm font-medium text-primary">{{ formatDate(largePost.created_at) }}</span>
              </div>
              <div>
                <p class="m-0 line-clamp-2 text-black md:line-clamp-6">
                  {{ largePost.post_content[0].teaser }}
                </p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </template>

    <template v-if="loading && !posts.length">
      <div class="z-10 col-span-12 mb-4 md:col-span-6 lg:col-span-3">
        <CiSkeletonItem class="h-60 w-full rounded md:h-80 lg:h-[25rem]" />
      </div>
      <div class="z-10 col-span-12 mb-4 md:col-span-6 lg:col-span-3">
        <CiSkeletonItem class="h-60 w-full rounded md:h-80 lg:h-[25rem]" />
      </div>
      <div class="z-10 col-span-12 mb-4 lg:col-span-6">
        <CiSkeletonItem class="h-[25rem] w-full rounded md:h-80 lg:h-[25rem]" />
      </div>
    </template>
  </section>
</template>

<style></style>
