<script setup lang="ts">
// icons
import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiCampsiteHeroProps {
  headline?: string | null;
  hero: any;
}
const props = withDefaults(defineProps<CiCampsiteHeroProps>(), {
  headline: null,
});

/********************
 * COMPOSITIONS      *
 ********************/
const backendStore = useBackendStore();
const loadingStore = useLoadingStore();
const localePath = useLocalePath();

/********************
 * REFS & VARS       *
 ********************/
const campsiteSlug = computed(() => {
  return props.hero?.campsite?.slug || '';
});

const fallbackImage = computed(() => {
  if (!props.hero?.campsite) {
    return {
      url: '/assets/img/hero_placeholder@1400.jpg',
      width: 1400,
      height: 485,
    };
  } else {
    return {
      url: fixImageUrl(backendStore.url, props.hero.images[0].url),
      width: props.hero.images[0].width,
      height: props.hero.images[0].height,
    };
  }
});

const campsiteName = computed(() => {
  return props.hero?.campsite?.name || '';
});

const location = computed(() => {
  if (!props.hero?.campsite) {
    return '';
  }
  return `${props.hero.campsite.location.city} (${props.hero.campsite.location.state})`;
});

/********************
 * FUNCTIONS         *
 ********************/
function getImageSources(imageType: 'jpg' | 'webp') {
  if (loadingStore.isLoading('getHomeData') || (props.hero && !props.hero.images)) {
    return [];
  }

  const webp = imageType === 'webp';

  if (props.hero) {
    const imageSources: object[] = [];
    const imgCopy = [...props.hero.images];
    imgCopy.sort((a, b) => {
      if (a.width < b.width) {
        return 1;
      }
      if (a.width > b.width) {
        return -1;
      }
      return 0;
    });
    imgCopy.forEach((img, idx) => {
      const url = addWebp(fixImageUrl(backendStore.url, img.url), webp);
      let minWidth = 0;
      if (idx < imgCopy.length - 1) {
        // set mediaquery min-width to next smaller width plus 100
        minWidth = imgCopy[idx + 1].width + 100;
      }
      imageSources.push({
        srcset: `${url} ${img.width}w,`,
        media: `(min-width: ${minWidth}px)`,
        type: `image/${imageType}`,
        key: `${imageType}.${idx}`,
      });
    });
    return imageSources;
  }
  return [];
}
</script>

<template>
  <div
    class="after:content-[' '] relative after:absolute after:inset-x-0 after:top-full after:-z-1 after:block after:h-[25rem] after:w-full after:bg-gray-10 lg:after:top-[26.5rem] lg:after:h-[27rem]"
  >
    <div
      class="relative z-[1] mx-auto my-0 h-[22.75rem] max-w-screen-xxl overflow-hidden rounded-none bg-cover p-0 md:h-[32rem] xxl:rounded-b-md"
    >
      <template v-if="!loadingStore.isLoading('getHomeData') && campsiteSlug">
        <picture>
          <source
            v-for="source of getImageSources('webp')"
            :key="source.key"
            :srcset="source.srcset"
            :media="source.media"
            :type="source.type"
          />
          <source
            v-for="source of getImageSources('jpg')"
            :key="source.key"
            :srcset="source.srcset"
            :media="source.media"
            :type="source.type"
          />
          <img
            class="absolute left-0 top-0 size-full object-cover"
            :src="fallbackImage.url"
            :width="fallbackImage.width"
            :height="fallbackImage.height"
            alt="Camping.info"
            decoding="async"
            loading="eager"
            fetchpriority="high"
          />
        </picture>
        <div class="absolute inset-0 size-full">
          <div class="flex h-full items-center justify-center overflow-x-hidden">
            <div
              class="after:content-[' '] relative flex flex-col after:absolute after:-left-80 after:top-1/2 after:block after:h-[calc(100%+10rem)] after:w-[calc(100%+40rem)] after:-translate-y-1/2 after:bg-[radial-gradient(ellipse_at_center,rgba(14,31,18,1)_0%,rgba(14,31,18,0)_50%)] after:opacity-40"
            >
              <h1
                class="z-[1] m-0 text-balance text-center text-[calc(1.375rem+1.5vw)] font-normal leading-[1.2] text-white xl:text-[2.5rem]"
                v-html="props.headline"
              />
            </div>
          </div>
          <nuxt-link
            v-if="campsiteName && campsiteSlug && location"
            :to="localePath({ name: RBN_CAMPSITE_DETAIL, params: { identifier: campsiteSlug } })"
            class="text-small-book absolute bottom-0 right-0 w-full bg-black/60 bg-center pb-10 pl-[0.938rem] pr-4 pt-2 text-white before:absolute before:-top-px before:right-0 before:block before:h-px before:w-full before:bg-[linear-gradient(90deg,rgba(151,151,151,0)_0,rgba(151,151,151,0.6)_30%,rgba(151,151,151,0.6)_85%,rgba(151,151,151,0)_100%)] before:content-[''] after:absolute after:-bottom-px after:right-0 after:block after:h-px after:w-full after:bg-[linear-gradient(90deg,rgba(151,151,151,0)_0,rgba(151,151,151,0.6)_30%,rgba(151,151,151,0.6)_85%,rgba(151,151,151,0)_100%)] after:content-[''] hover:no-underline md:bottom-12 md:w-auto md:bg-transparent md:bg-[linear-gradient(90deg,transparent,rgba(14,31,18,0.6)_30%,rgba(14,31,18,0.6)_100%)] md:py-2 md:pl-12 md:before:bg-[linear-gradient(90deg,rgba(151,151,151,0)_0,rgba(151,151,151,0.6)_30%,rgba(151,151,151,0.6)_100%)] md:after:bg-[linear-gradient(90deg,rgba(151,151,151,0)_0,rgba(151,151,151,0.6)_30%,rgba(151,151,151,0.6)_100%)] lg:bottom-8"
          >
            <span class="mb-1 text-center font-medium">{{ campsiteName }}</span>
            <div class="flex items-center pt-1">
              <CiAwesomeIcon
                :icon="faMapMarkerAlt"
                class="mr-2 fill-white"
                ratio="0.5"
              />
              <span>{{ location }}</span>
            </div>
          </nuxt-link>
        </div>
      </template>
    </div>
  </div>
</template>

<style></style>
